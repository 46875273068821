import '/assets/styles/pages/campaigns.scss';

import { useState, useEffect } from 'react';

// Partials
import Link from '@wearetla/tla-essentials-tools/partials/link';
import Loader from '/views/partials/loader'
import Img from '@wearetla/tla-essentials-tools/partials/img';
import EmptyMessage from '/views/partials/empty-message';

// Sections
import Breadcrumbs from '/views/sections/breadcrumbs'

// Services
import siteServices from '/services/site';

// Context
import { useGlobalState } from '@wearetla/tla-essentials-tools/utilities/global-state';
import Icon from '@wearetla/tla-essentials-tools/partials/icon';

const getCampaigns = () => {
	return siteServices.getCampaigns()
}

const Campaigns = () => {
	const [campaigns, setCampaigns] = useState(false);
	const { setError } = useGlobalState();

	useEffect(() => {
		if(!campaigns) {
			getCampaigns().then((payload) => {
				setCampaigns(payload);
			}).catch((e) => {
				setError();
			})
		}
	}, [campaigns])

	return (
		<main className="page campaigns">
			<Breadcrumbs data={[{name: 'Kampanyalar'}]} />
			<div className="campaigns-wrap wrapper loader-container">
				<Loader loading={!campaigns} />
				{(campaigns && campaigns.length !== 0) &&
					<ul className="campaigns-list">
						{campaigns.map((campaign, nth) => (
							<li key={nth} className="list-campaign">
								<Link
									href="campaignDetail"
									params={{
										slug: campaign.slug,
										id: campaign.id,
									}}
									className="campaign-button">
									<div className="campaign-imagewrap">
										<Img
											src={campaign.image}
											cover
											className="campaign-image" />
									</div>
									<strong className="campaign-title">{campaign.title}</strong>
									<p className="campaign-description">{campaign.sort_description}</p>

									<p className="campaign-ctawrap">Kampanya detayı <Icon name="arrow-right" /></p>
								</Link>
							</li>
						))}
					</ul>
				}
				{(campaigns && campaigns.length === 0) &&
					<EmptyMessage lined icon={false} className="campaigns-emptymessage">
						<p>Hiç aktif kampanya bulunmuyor.</p>
					</EmptyMessage>
				}
			</div>
		</main>
	)
}

export default Campaigns;